











































































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import emitter from './taint/Emitter'

@Component({ name: 'Login' })
export default class Login extends VueBase {
  private userName = ''
  private password = ''
  private captcha = ''
  private captcha_hash_key = ''
  private captcha_url = ''
  private login_lock = false
  private logo = '/upload/assets/img/logo.png?v=' + String(Math.random())
  private logo_en = '/upload/assets/img/logo_en.png?v=' + String(Math.random())
  changelogo() {
    this.logo_en = '/upload/assets/img/logo_en.png?v=' + String(Math.random())
    this.logo = '/upload/assets/img/logo.png?v=' + String(Math.random())
  }
  created() {
    emitter.on('changelogo', this.changelogo)
    this.initCaptcha()
  }

  private async initCaptcha() {
    const { status, data, msg } = await this.services.user.initCaptcha()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.captcha_url = data['image_url']
    this.captcha_hash_key = data['hash_key']
    this.captcha = ''
  }

  private async login() {
    if (this.login_lock) {
      return
    }
    this.login_lock = true
    const params = {
      username: this.userName,
      password: this.password,
      captcha: this.captcha,
      captcha_hash_key: this.captcha_hash_key,
    }
    this.loadingStart()
    const { status, data, msg } = await this.services.user.login(params)
    this.loadingDone()
    if (status === 201) {
      let lang =
        data.default_language ||
        (navigator as any).language ||
        (navigator as any).userLanguage
      lang = lang.substr(0, 2)
      await this.services.setting.setLang(lang)
      switch (lang) {
        case 'zh':
          this.$i18n.locale = 'zh_cn'
          break
        case 'en':
          this.$i18n.locale = 'en'
          break
        default:
          this.$i18n.locale = 'zh_cn'
          break
      }
      await this.$store.dispatch('user/getUserInfo')
      await this.$router.push('/project')
    } else if (status === 204) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
    } else if (status === 203 || status === 202) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      this.initCaptcha()
    }
    this.login_lock = false
  }
}
